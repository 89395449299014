<template>
    <div class="friendsCircle_explain_page">
      <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true }"></v-header>
      </div>
  
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item v-for="(item, index) in list" :key="index" :title="item.title" :name="index + 1">
          <div v-html="item.content"></div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </template>
  
  <script>
  import vHeader from "@/components/header.vue";
  export default{
      components: {
      vHeader
    },
    data(){
      return{
          title: this.$route.meta.title,
          activeName: '1',
          list: [
              {title:'1. 头尾大师',content:'<p>1、近10期至少发表过8期【头尾】预测帖</p><p>2、近10期至少命中5次【头尾】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'2. 中肚大师',content:'<p>1、近10期至少发表过8期【中肚】预测帖</p><p>2、近10期至少命中5次【中肚】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'3. 芝麻大师',content:'<p>1、近10期至少发表过8期【芝麻】预测帖</p><p>2、近10期至少命中3次【芝麻】</p><p>3、回报率 >= 130%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'4. 定头大师',content:'<p>1、近10期至少发表过8期【定头】预测帖</p><p>2、近10期至少命中7次【定头】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'5. 定百大师',content:'<p>1、近10期至少发表过8期【定百】预测帖</p><p>2、近10期至少命中7次【定百】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'6. 定十大师',content:'<p>1、近10期至少发表过8期【定十】预测帖</p><p>2、近10期至少命中7次【定十】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'7. 定尾大师',content:'<p>1、近10期至少发表过8期【定尾】预测帖</p><p>2、近10期至少命中7次【定尾】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'8. 杀头大师',content:'<p>1、近10期至少发表过10期【杀头】预测帖</p><p>2、近10期至少命中10次【杀头】</p><p>3、回报率 >= 10%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'9. 杀百大师',content:'<p>1、近10期至少发表过10期【杀百】预测帖</p><p>2、近10期至少命中10次【杀百】</p><p>3、回报率 >= 10%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'10. 杀十大师',content:'<p>1、近10期至少发表过10期【杀十】预测帖</p><p>2、近10期至少命中10次【杀十】</p><p>3、回报率 >= 10%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'11. 杀尾大师',content:'<p>1、近10期至少发表过10期【杀尾】预测帖</p><p>2、近10期至少命中10次【杀尾】</p><p>3、回报率 >= 10%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'12. 头尾合大师',content:'<p>1、近10期至少发表过8期【头尾合】预测帖</p><p>2、近10期至少命中7次【头尾合】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'13. 中肚合大师',content:'<p>1、近10期至少发表过8期【中肚合】预测帖</p><p>2、近10期至少命中7次【中肚合】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'14. 稳码大师',content:'<p>1、近10期至少发表过8期【稳码】预测帖</p><p>2、近10期至少命中5次【稳码】</p><p>3、回报率 >= 45%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'15. 死数大师',content:'<p>1、近10期至少发表过10期【死数】预测帖</p><p>2、近10期至少命中9次【死数】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'16. ABXX大师',content:'<p>1、近10期至少发表过8期【ABXX】预测帖</p><p>2、近10期至少命中5次【ABXX】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'17. AXCX大师',content:'<p>1、近10期至少发表过8期【AXCX】预测帖</p><p>2、近10期至少命中5次【AXCX】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'18. XBXD大师',content:'<p>1、近10期至少发表过8期【XBXD】预测帖</p><p>2、近10期至少命中5次【XBXD】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'19. XXCD大师',content:'<p>1、近10期至少发表过8期【XXCD】预测帖</p><p>2、近10期至少命中5次【XXCD】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'20. ABCX大师',content:'<p>1、近10期至少发表过8期【ABCX】预测帖</p><p>2、近10期至少命中4次【ABCX】</p><p>3、回报率 >= 85%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'21. ABXD大师',content:'<p>1、近10期至少发表过8期【ABXD】预测帖</p><p>2、近10期至少命中4次【ABXD】</p><p>3、回报率 >= 85%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'22. AXCD大师',content:'<p>1、近10期至少发表过8期【AXCD】预测帖</p><p>2、近10期至少命中4次【AXCD】</p><p>3、回报率 >= 85%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'23. XBCD大师',content:'<p>1、近10期至少发表过8期【XBCD】预测帖</p><p>2、近10期至少命中4次【XBCD】</p><p>3、回报率 >= 85%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'24. 二字现大师',content:'<p>1、近10期至少发表过8期【二字现】预测帖</p><p>2、近10期至少命中6次【二字现】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
              {title:'25. 三字现大师',content:'<p>1、近10期至少发表过8期【三字现】预测帖</p><p>2、近10期至少命中4次【三字现】</p><p>3、回报率 >= 30%</p><p>若满足条件者众多，取回报率高到低排序前20名</p>'},
             
          ],
      }
    }
  }
  
  
  
  </script>
  
  <style>
  
  </style>